import { startTransition } from "react";
import ReactGA from "react-ga4";
import { RemixBrowser } from "@remix-run/react";
import { hydrateRoot } from "react-dom/client";
import { Provider } from "react-redux";

import store from "store";

import { reactiveLocalStorage } from "operations/storage";

window.extensionScripts = [];

const scripts = [...document.getElementsByTagName("script")];
for (const child of scripts) {
  if (
    child.src.startsWith("chrome-extension:") ||
    child.src.startsWith("moz-extension:")
  ) {
    child.parentElement.removeChild(child);

    window.extensionScripts.push(child);
  }
}

startTransition(() => {
  reactiveLocalStorage.subscribeWindow();

  hydrateRoot(
    document,
    <Provider store={store}>
      <RemixBrowser />
    </Provider>,
  );
  ReactGA.initialize("G-79X2R2HCZS", { gtagOptions: { send_page_view: true } });
});
